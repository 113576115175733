import { useRef, type RefObject } from 'react'

/**
 * Custom hook that provides a form reference and a keydown event handler to submit the form
 * when the Enter key is pressed.
 *
 * This hook is particularly useful for text areas where pressing Enter should submit the form
 * instead of adding a new line, unless the Shift key is held down.
 *
 * @example
 *   const { formRef, onKeyDown } = useEnterSubmit();
 *
 *   return (
 *   <form ref={formRef}>
 *   <textarea onKeyDown={onKeyDown}></textarea>
 *   </form>
 *   );
 *
 * @returns {Object} An object containing:
 *
 *   - FormRef: A reference to the form element.
 *   - OnKeyDown: A keydown event handler function for the text area.
 */
export function useEnterSubmit(): {
  formRef: RefObject<HTMLFormElement>
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
} {
  const formRef = useRef<HTMLFormElement>(null)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter' && !event.shiftKey && !event.nativeEvent.isComposing) {
      formRef.current?.requestSubmit()
      event.preventDefault()
    }
  }

  return { formRef, onKeyDown: handleKeyDown }
}
