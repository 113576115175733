import { useCallback, useEffect, useState } from 'react'
import Image from 'next/image'
import { AnimatePresence, motion } from 'framer-motion'
import {
  BeakerIcon,
  BoltIcon,
  Clock,
  FolderOpen,
  GraduationCap,
  Heart,
  MessageSquarePlus,
  PencilIcon,
  Phone,
  SparklesIcon,
  Trash2,
  Wand2
} from 'lucide-react'

import { Advisor, advisors } from '@/app/advisors'
import { AttachmentIcon } from '@/app/components/attachment-icon'
import { DarkModeToggle } from '@/app/components/dark-mode-toggle'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/app/components/ui/alert-dialog'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from '@/app/components/ui/sheet'
import { SidebarTrigger } from '@/app/components/ui/sidebar'
import { logger } from '@/app/lib/logging'
import { Attachment, ConciergeData, NexusState } from '@/app/lib/types'
import { deleteAttachment, getAttachments } from '@/app/si/attachments'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { Button } from './ui/button'
import { useToast } from './ui/use-toast'
import { UserProfile } from './user-profile'

export function Topbar({ nexusState }: { nexusState: NexusState }) {
  const { anonymousUserId, user, isUserLoaded, setMessageAttachments, handleNewConversation } =
    nexusState

  const { toast } = useToast()
  const [isLoadingStoredAttachments, setIsLoadingStoredAttachments] = useState(false)
  const [storedAttachments, setStoredAttachments] = useState<Attachment[]>([])
  const [isAttachmentsSheetOpen, setIsAttachmentsSheetOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [attachmentToDelete, setAttachmentToDelete] = useState<string | null>(null)

  const selectedAdvisor = advisors[0] // Assuming we're using the first advisor for now

  const handleFetchStoredAttachments = useCallback(async () => {
    if (!isUserLoaded) return
    // Only fetch attachments if we have a valid anonymousUserId or user
    if (!anonymousUserId && !user) {
      logger.warn('Attempted to fetch attachments without a valid user ID')
      toast({
        title: 'Unable to fetch attachments',
        description: 'Please try again in a moment.',
        duration: 5000
      })
      return
    }

    setIsLoadingStoredAttachments(true)
    try {
      // anonymousUserId will be undefined if the user is logged in
      const fetchedAttachments = await getAttachments(anonymousUserId)
      setStoredAttachments(fetchedAttachments.slice(0, 25)) // Get the 25 most recent attachments
    } catch (error) {
      logger.error('Failed to get attachments:', error)
      toast({
        title: 'Error fetching attachments',
        description: 'Unable to load attachments. Please try again.',
        duration: 5000
      })
    } finally {
      setIsLoadingStoredAttachments(false)
    }
  }, [user, anonymousUserId, toast, isUserLoaded])

  useEffect(() => {
    if (isUserLoaded && anonymousUserId) {
      handleFetchStoredAttachments()
    }
  }, [anonymousUserId, user, handleFetchStoredAttachments, isUserLoaded])

  const handleAttachmentClick = (attachment: Attachment) => {
    setMessageAttachments(prevAttachments => {
      const isDuplicate = prevAttachments.some(a => a.uuid === attachment.uuid)
      return isDuplicate ? prevAttachments : [...prevAttachments, attachment]
    })
    setIsAttachmentsSheetOpen(false)
  }

  const handleDeleteAttachment = useCallback(async (uuid: string) => {
    setAttachmentToDelete(uuid)
    setIsDeleteDialogOpen(true)
  }, [])

  const confirmDeleteAttachment = useCallback(async () => {
    if (!attachmentToDelete) return

    try {
      // anonymousUserId will be undefined if the user is logged in
      const result = await deleteAttachment(attachmentToDelete, anonymousUserId)
      if (result.success) {
        setStoredAttachments(prevAttachments =>
          prevAttachments.filter(attachment => attachment.uuid !== attachmentToDelete)
        )
        toast({
          title: 'Attachment deleted',
          description: 'The attachment has been successfully deleted.',
          duration: 3000
        })
      } else {
        throw new Error('Failed to delete attachment')
      }
    } catch (error) {
      logger.error('Error deleting attachment:', error)
      toast({
        title: 'Error',
        description: 'Failed to delete the attachment. Please try again.',
        variant: 'destructive',
        duration: 5000
      })
    } finally {
      setIsDeleteDialogOpen(false)
      setAttachmentToDelete(null)
    }
  }, [attachmentToDelete, anonymousUserId, toast, setStoredAttachments])

  const attachmentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
        delay: index * 0.05
      }
    })
  }

  return (
    <div className="flex items-center bg-background px-4 py-2 dark:bg-dark-base">
      <div className="sm:hidden">
        <SidebarTrigger className="mt-2 p-5" />
      </div>

      {/* Advisor Info */}
      <div className="flex flex-grow items-center">
        <div className="hidden sm:block">
          <AdvisorProfileCard advisor={selectedAdvisor} nexusState={nexusState} />
        </div>
      </div>

      <div className="mt-2 flex items-center space-x-4 sm:mt-0">
        {/* New Conversation button */}
        <Button
          data-testid="new-conversation-button"
          className="new-conversation"
          variant="ghost"
          size="icon"
          onClick={handleNewConversation}
        >
          <MessageSquarePlus className="h-5 w-5" />
          <span className="sr-only">New Conversation</span>
        </Button>

        {/* Attachments button */}
        <Sheet open={isAttachmentsSheetOpen} onOpenChange={setIsAttachmentsSheetOpen}>
          <SheetTrigger asChild>
            <Button
              className="attachments"
              variant="ghost"
              size="icon"
              onClick={handleFetchStoredAttachments}
            >
              <FolderOpen className="h-5 w-5" />
              <span className="sr-only">Attachments</span>
            </Button>
          </SheetTrigger>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>Attachments</SheetTitle>
              <SheetDescription>View and manage your uploaded attachments</SheetDescription>
            </SheetHeader>
            <div className="mt-4 space-y-2">
              {isLoadingStoredAttachments ? (
                <p>Loading...</p>
              ) : storedAttachments.length > 0 ? (
                <AnimatePresence>
                  <ul className="max-h-[calc(100vh-200px)] space-y-2 overflow-auto">
                    {storedAttachments.map((attachment, index) => (
                      <motion.li
                        key={attachment.uuid}
                        custom={index}
                        variants={attachmentVariants}
                        initial="hidden"
                        animate="visible"
                        className="flex items-center space-x-3 rounded p-2 text-sm hover:bg-gray-100 dark:hover:bg-dark-base-hover"
                      >
                        <div
                          className="flex flex-grow cursor-pointer items-center space-x-3 overflow-hidden"
                          onClick={() => handleAttachmentClick(attachment)}
                        >
                          <AttachmentIcon attachment={attachment} width={40} height={40} />
                          <div className="min-w-0 flex-1">
                            <p className="truncate font-medium">{attachment.name}</p>
                            <p className="text-xs text-gray-500">
                              {formatFileSize(attachment.size)}
                            </p>
                          </div>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={e => {
                            e.stopPropagation()
                            handleDeleteAttachment(attachment.uuid)
                          }}
                          className="flex-shrink-0 text-gray-500 transition-colors hover:text-red-500"
                          aria-label={`Delete ${attachment.name}`}
                        >
                          <Trash2 size={16} />
                        </Button>
                      </motion.li>
                    ))}
                  </ul>
                </AnimatePresence>
              ) : (
                <p className="text-sm text-gray-500">No attachments found.</p>
              )}
            </div>
          </SheetContent>
        </Sheet>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200"></div>

        {/* Voice call button (currently shows a toast message) */}
        <Button
          variant="ghost"
          size="icon"
          onClick={() =>
            toast({
              title: 'Voice chat coming soon',
              description:
                "You'll be able to make voice calls with Cora, when the large language models are ready.",
              duration: 5000
            })
          }
        >
          <Phone className="h-5 w-5" />
          <span className="sr-only">Voice Call</span>
        </Button>

        <DarkModeToggle />

        <UserProfile nexusState={nexusState} />
      </div>

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Attachment</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this attachment? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteAttachment}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

function ConciergeInfoCard({ conciergeData }: { conciergeData: ConciergeData }) {
  return (
    <div className="space-y-4 p-4">
      {/* Display provider logo and model information */}
      <div className="flex items-center space-x-2">
        <Image
          src={conciergeData.providerLogo || ''}
          alt={`${conciergeData.humanProvider} logo`}
          className="h-8 w-8"
          width={32}
          height={32}
        />
        <div>
          <h3 className="text-sm font-semibold">{conciergeData.humanProvider}</h3>
          <p className="text-xs text-gray-500">{conciergeData.humanModel}</p>
        </div>
      </div>
      {/* Display response strategy information */}
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          {getStrategyIcon(conciergeData.responseStrategy)}
          <span className="text-sm font-medium">Response Strategy:</span>
          <span className="text-sm">{conciergeData.responseStrategy}</span>
        </div>
        <p className="text-xs text-gray-600">
          Your request was optimized for {conciergeData.responseStrategy} processing.
        </p>
      </div>
      {/* Display detected mood */}
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Heart className="h-4 w-4 text-red-500" />
          <span className="text-sm font-medium">Feeling the mood:</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm">
            {conciergeData.moodEmoji} - {conciergeData.mood}
          </span>
        </div>
        <p className="text-xs text-gray-600">
          Cora tailored the response based on the interpretation of your request.
        </p>
      </div>
      {/* Informative message about query enhancement */}
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Wand2 className="h-4 w-4 text-indigo-500" />
          <span className="text-sm font-medium">Prompt Engineering</span>
        </div>
        <p className="text-xs text-gray-600">
          Cora enhanced your query to provide a more helpful response.
        </p>
      </div>
    </div>
  )
}

function AdvisorProfileCard({
  advisor,
  nexusState
}: {
  advisor: Advisor
  nexusState: NexusState
}) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <div
          id="advisor-profile"
          className="flex cursor-pointer items-center space-x-4 rounded-lg p-2 transition-colors duration-200 hover:bg-gray-100"
        >
          <Avatar>
            <AvatarImage src={advisor.avatarUrl} alt={advisor.name} />
            <AvatarFallback>{advisor.name[0]}</AvatarFallback>
          </Avatar>
          <div>
            <h2 className="text-lg font-semibold">{advisor.name}</h2>
            <p className="hidden text-sm text-gray-500 sm:block">{advisor.specialization}</p>
          </div>
        </div>
      </SheetTrigger>
      <SheetContent
        side="left"
        className="overflow-y-auto bg-gradient-to-br from-gray-50 to-white sm:max-w-md"
      >
        <div className="flex flex-col items-center space-y-6 p-4">
          <div className="w-full text-center">
            <h2 className="text-2xl font-bold">{advisor.name}</h2>
            <p className="text-gray-500">{advisor.specialization}</p>
          </div>
          <Avatar className="h-24 w-24 shadow-lg">
            <AvatarImage src={advisor.avatarUrl} alt={advisor.name} />
            <AvatarFallback>{advisor.name[0]}</AvatarFallback>
          </Avatar>
          <p className="text-sm text-gray-700">{advisor.description}</p>
          <div className="w-full space-y-4 text-sm">
            <div className="flex items-center space-x-3">
              <GraduationCap className="h-5 w-5 text-primary" />
              <span className="font-medium">Expertise:</span>
              <span>{advisor.specialization}</span>
            </div>
            <div className="flex items-center space-x-3">
              <Clock className="h-5 w-5 text-primary" />
              <span className="font-medium">Availability:</span>
              <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-semibold text-green-800">
                24/7
              </span>
            </div>
            <div className="flex items-center space-x-3">
              {getStrategyIcon(advisor.responseStrategy)}
              <span className="font-medium">Response Style:</span>
              <span className="capitalize">{advisor.responseStrategy}</span>
            </div>
            <div className="space-y-2">
              <div className="flex items-center space-x-3">
                <Wand2 className="h-5 w-5 text-primary" />
                <span className="font-medium">Creativity Spectrum:</span>
              </div>
              <div className="relative h-2 w-full rounded-full bg-gray-200">
                <div
                  className="absolute h-full rounded-full bg-gradient-to-r from-blue-500 to-purple-500"
                  style={{ width: `${advisor.temperature * 100}%` }}
                ></div>
              </div>
              <div className="flex justify-between text-xs text-gray-600">
                <span>Precise</span>
                <span>Creative</span>
              </div>
            </div>
            <div className="space-y-2">
              <span className="font-medium">Personality:</span>
              <p className="text-gray-700">{advisor.personality}</p>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}

function getStrategyIcon(strategy: 'fast' | 'reasoning' | 'eloquent' | 'creative') {
  switch (strategy) {
    case 'fast':
      return <BoltIcon className="h-4 w-4 text-yellow-500" />
    case 'reasoning':
      return <BeakerIcon className="h-4 w-4 text-blue-500" />
    case 'eloquent':
      return <PencilIcon className="h-4 w-4 text-purple-500" />
    case 'creative':
      return <SparklesIcon className="h-4 w-4 text-pink-500" />
  }
}

const formatFileSize = (bytes: number) => {
  if (bytes < 1024) return bytes + ' B'
  else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB'
  else return (bytes / 1048576).toFixed(1) + ' MB'
}
