// This tells Next.js that this file is a server-side file for server actions
'use server'

import { logger } from '@/app/lib/logging'
import { getRedisClient } from '@/app/lib/redis'
import { getUserId } from '@/app/lib/server-utils'
import { type Conversation } from '@/app/lib/types'

/**
 * Redis Key Structure:
 *
 * Conversation:<id> -> Stores individual conversation data
 *
 * |
 *
 * Conversation_list:<userId> -> Sorted set of conversation keys | +-- conversation:<id1>
 * (score: timestamp) +-- conversation:<id2> (score: timestamp) +-- ...
 */

/**
 * Retrieve the most recent conversations for a given user, limited to 15.
 *
 * @param providedUserId - The ID of the user whose conversations to fetch.
 * @returns An array of up to 15 most recent Conversation objects.
 */
export async function getConversations(
  providedUserId?: string | undefined
): Promise<Conversation[]> {
  const userId = await getUserId(providedUserId)
  if (!userId) {
    logger.warn('No user found, returning empty conversation list')
    return []
  }

  try {
    const client = await getRedisClient()
    // Get the 15 most recent conversation keys, sorted by score (timestamp) in descending order
    const conversationKeys = await client.zRange(`conversation_list:${userId}`, 0, 14, {
      REV: true
    })

    if (conversationKeys.length === 0) {
      return []
    }

    const results = await client.mGet(conversationKeys)
    return results
      .filter((result): result is string => result !== null)
      .map(result => {
        const parsed = JSON.parse(result) as Conversation
        parsed.createdAt = new Date(parsed.createdAt)
        parsed.updatedAt = new Date(parsed.updatedAt)
        return parsed
      })
  } catch (error) {
    logger.error('Failed to get conversations:', error)
    throw new Error(
      "We couldn't fetch your conversations right now. Let's try again in a moment."
    )
  }
}

/**
 * Retrieve a specific conversation for a user.
 *
 * @param id - The ID of the conversation to fetch.
 * @param providedUserId - Optional user ID to use instead of the session user.
 * @returns The Conversation object if found and authorized, or null otherwise.
 */
export async function getConversation(id: string, providedUserId?: string | undefined) {
  try {
    const userId = await getUserId(providedUserId)
    const client = await getRedisClient()

    const conversation = await client.get(`conversation:${id}`)
    logger.debug('Fetching conversation', { id, userId })

    if (!conversation) {
      return null
    }

    const parsed = JSON.parse(conversation) as Conversation

    if (parsed.userId !== userId) {
      // This shouldn't happen. We had a bug where the user ID was not being set.
      // Leaving this here so that we can see if people are trying to fetch other people's conversations.
      logger.warn('Conversation user ID mismatch when retrieving conversation', {
        conversationUserId: parsed.userId,
        determinedUserId: userId
      })
      // After we clear out the bad data, this will start returning null again.
      // return null
    }

    parsed.createdAt = new Date(parsed.createdAt)
    parsed.updatedAt = new Date(parsed.updatedAt)
    return parsed
  } catch (error) {
    logger.error('Failed to get conversation:', error)
    throw new Error(
      "We couldn't retrieve that conversation right now. Let's try again in a moment."
    )
  }
}

/**
 * Remove a specific conversation for a user.
 *
 * @param id - The ID of the conversation to remove.
 * @param providedUserId - Optional user ID to use instead of the session user.
 * @returns An object indicating the success of the operation.
 */
export async function removeConversation(id: string, providedUserId?: string | undefined) {
  try {
    const userId = await getUserId(providedUserId)
    if (!userId) {
      throw new Error('User ID is required')
    }

    const client = await getRedisClient()

    const conversation = await client.get(`conversation:${id}`)
    if (!conversation) {
      return { success: false, message: 'Conversation not found' }
    }

    const parsedConversation = JSON.parse(conversation) as Conversation
    if (parsedConversation.userId !== userId) {
      return { success: false, message: 'Unauthorized' }
    }

    const multi = client.multi()
    multi.del(`conversation:${id}`)
    multi.zRem(`conversation_list:${userId}`, `conversation:${id}`)
    await multi.exec()

    logger.info('Conversation removed successfully', { conversationId: id, userId })
    return { success: true, message: 'Conversation deleted successfully' }
  } catch (error) {
    logger.error('Failed to remove conversation:', error)
    return {
      success: false,
      message: "We couldn't remove that conversation right now. Let's try again in a moment."
    }
  }
}

/**
 * Clear all conversations for a user.
 *
 * @param providedUserId - Optional user ID to use instead of the session user.
 * @returns An object indicating the success of the operation and a message.
 */
export async function clearConversations(providedUserId?: string | undefined) {
  try {
    const userId = await getUserId(providedUserId)
    const client = await getRedisClient()

    const conversations: string[] = await client.zRange(`conversation_list:${userId}`, 0, -1)
    if (!conversations.length) {
      return { success: true, message: 'No conversations to clear' }
    }

    const multi = client.multi()

    for (const conversation of conversations) {
      multi.del(conversation)
      multi.zRem(`conversation_list:${userId}`, conversation)
    }

    await multi.exec()

    return { success: true, message: 'All conversations cleared' }
  } catch (error) {
    logger.error('Failed to clear conversations:', error)
    throw new Error(
      "We couldn't clear your conversations right now. Let's try again in a moment."
    )
  }
}

/**
 * Save a new conversation or update an existing one for a user.
 *
 * @param conversation - The Conversation object to save.
 * @param providedUserId - Optional user ID to use instead of the session user.
 * @returns An object indicating the success of the operation.
 */
export async function saveConversation(
  conversation: Omit<Conversation, 'createdAt' | 'updatedAt'>,
  providedUserId?: string | undefined
) {
  try {
    const userId = await getUserId(providedUserId)
    if (!userId) {
      throw new Error('User ID is required')
    }

    const client = await getRedisClient()

    const now = new Date()
    const conversationKey = `conversation:${conversation.id}`
    const existingConversation = await client.get(conversationKey)

    let createdAt = existingConversation
      ? new Date(JSON.parse(existingConversation).createdAt)
      : now

    const serializedConversation = JSON.stringify({
      ...conversation,
      userId,
      createdAt: createdAt.toISOString(),
      updatedAt: now.toISOString()
    })

    await client.set(conversationKey, serializedConversation)
    await client.zAdd(`conversation_list:${userId}`, {
      score: now.getTime(),
      value: conversationKey
    })

    logger.debug('Conversation saved successfully', {
      conversationId: conversation.id,
      userId
    })
    return { success: true }
  } catch (error) {
    logger.error('Failed to save conversation:', error)
    throw new Error(
      "We couldn't save your conversation right now. Let's try again in a moment."
    )
  }
}

export async function renameConversation(
  id: string,
  newTitle: string,
  providedUserId?: string | undefined
) {
  try {
    const userId = await getUserId(providedUserId)
    if (!userId) {
      throw new Error('User ID is required')
    }

    const client = await getRedisClient()

    const conversationKey = `conversation:${id}`
    const conversation = await client.get(conversationKey)

    if (!conversation) {
      return { success: false, message: 'Conversation not found' }
    }

    const parsedConversation = JSON.parse(conversation) as Conversation
    if (parsedConversation.userId !== userId) {
      return { success: false, message: 'Unauthorized' }
    }

    parsedConversation.title = newTitle

    await client.set(conversationKey, JSON.stringify(parsedConversation))

    logger.debug('Conversation renamed successfully', { conversationId: id, userId, newTitle })
    return { success: true, message: 'Conversation renamed successfully' }
  } catch (error) {
    logger.error('Failed to rename conversation:', error)
    return {
      success: false,
      message: "We couldn't rename that conversation right now. Let's try again in a moment."
    }
  }
}
