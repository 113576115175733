import { Copy } from 'lucide-react'

import { Button } from '@/app/components/ui/button'
import { toast } from '@/app/components/ui/use-toast'
import { logger } from '@/app/lib/logging'

/**
 * Handles errors by logging them and displaying a toast notification.
 *
 * @param {unknown} error - The error object.
 * @param {string} context - The context in which the error occurred.
 */
export function handleError(error: unknown, context: string) {
  logger.error(`Error in ${context}:`, error)

  const errorMessage = error instanceof Error ? error.message : String(error)
  const errorStack = error instanceof Error ? error.stack : 'No stack trace available'

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast({ title: 'Error details copied', duration: 2000 }))
  }

  const isDevelopment = process.env.NODE_ENV === 'development'

  toast({
    title: isDevelopment ? `Error from ${context}` : 'Oops! Something went wrong.',
    description: (
      <div className="space-y-2 p-4">
        <div className="max-h-32 overflow-auto whitespace-pre-wrap rounded bg-secondary/50 p-4 text-sm">
          {errorMessage}
        </div>
        {isDevelopment && (
          <Button
            variant="secondary"
            size="sm"
            className="mt-2 text-primary hover:bg-secondary/80"
            onClick={() =>
              copyToClipboard(`Error in ${context}:\n${errorMessage}\n\nStack:\n${errorStack}`)
            }
          >
            <Copy className="mr-2 h-4 w-4" />
            Copy Stack Trace
          </Button>
        )}
      </div>
    ),
    variant: 'destructive',
    duration: 30_000
  })
}
