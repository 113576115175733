import util from 'util'

/**
 * Logger utility to log messages with different levels and colors.
 *
 * Usage: logger.info('This is an info message'); logger.error('This is an error message', {
 * errorDetails: 'details' }); logger.debug('Debugging object:', { key: 'value' }, 'additional
 * info');
 *
 * Log Levels:
 *
 * - Trace: Detailed information, typically of interest only when diagnosing problems.
 * - Debug: Information useful to developers for debugging the application.
 * - Info: Informational messages that highlight the progress of the application.
 * - Warn: Potentially harmful situations.
 * - Error: Error events that might still allow the application to continue running.
 * - Fatal: Very severe error events that will presumably lead the application to abort.
 *
 * Note:
 *
 * - You can log multiple arguments, including objects, and they will be pretty printed.
 */
export const logger = {
  trace: (...args: any[]) => log('trace', ...args),
  debug: (...args: any[]) => log('debug', ...args),
  info: (...args: any[]) => log('info', ...args),
  warn: (...args: any[]) => log('warn', ...args),
  error: (...args: any[]) => log('error', ...args),
  fatal: (...args: any[]) => log('fatal', ...args)
}

// Define log levels and their corresponding colors
const logLevels = {
  trace: '\x1b[34m', // Blue
  debug: '\x1b[36m', // Cyan
  info: '\x1b[32m', // Green
  warn: '\x1b[33m', // Yellow
  error: '\x1b[31m', // Red
  fatal: '\x1b[35m' // Magenta
}

// Reset color
const resetColor = '\x1b[0m'

// Assign numeric values to log levels for comparison
const logLevelValues = {
  trace: 10,
  debug: 20,
  info: 30,
  warn: 40,
  error: 50,
  fatal: 60
}

// Get the current log level from environment variable, default to 'info'
const currentLogLevel = (() => {
  const level = (
    process.env.NEXT_PUBLIC_LOG_LEVEL || 'info'
  ).toLowerCase() as keyof typeof logLevelValues
  return logLevelValues[level] ?? logLevelValues['info']
})()

// Function to format log messages
function formatLogMessage(level: keyof typeof logLevels, args: any[]): string {
  const color = logLevels[level] || ''
  const formattedArgs = args
    .map(arg =>
      typeof arg === 'object'
        ? util.inspect(arg, { colors: true, depth: null, compact: false, sorted: true })
        : arg
    )
    .join(' ')
  return `${color}[${level.toUpperCase()}]${resetColor} ${formattedArgs}`
}

// Function to log messages based on level
function log(level: keyof typeof logLevels, ...args: any[]): void {
  if (logLevelValues[level] < currentLogLevel) return
  const logMessage = formatLogMessage(level, args)

  switch (level) {
    case 'warn':
      console.warn(logMessage)
      break
    case 'error':
      console.error(logMessage)
      break
    case 'fatal':
      console.error(logMessage)
      break
    default:
      console.log(logMessage)
  }
}
